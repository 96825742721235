<template>
  <div class="px-8">
    <div class="title">
      <h3>{{layouts.data.title}}</h3>
    </div>

    <div class="d-flex flex-column justify-space-around" v-min-height="400">
      <v-icon color="var(--theme_primary)" size="100" class="mb-5">{{layouts.data.icon}}</v-icon>
      <div class="f-s-16">
        {{ layouts.data.primaryText }}
        <div class="px-2 f-s-16 width-100p" v-if="layouts.data.radioGroupDisplay">
          <!--        <div>Make your choice and click <span class="font-italic f-w-900">CONTINUE</span>:</div>-->
          <v-radio-group v-model="layouts.data.radioGroupValue">
            <v-radio
              v-for="(item, index) in layouts.settings.radioGroup"
              :key="index"
              :label="item.text"
              color="var(--theme_primary)"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>

    </div>

    <web-btn
      :height="50"
      width="100%"
      @click="onClickContinue"
    >Continue
    </web-btn>
  </div>
</template>

<script>
import WebBtn from '@/components/base/WebBtn'
import { DataType } from '@/assets/js/util'
export default {
  name: 'Requirement',
  components: {
    WebBtn
  },
  data () {
    return {
      layouts: {
        data: {
          title: '',
          icon: '',
          primaryText: '',
          radioGroupValue: 1,
          radioGroupDisplay: false
        },
        settings: {
          radioGroup: [
            {
              text: 'Upload Bank Statements',
              value: 1
            },
            {
              text: 'Change Bank Account and Re-Authorize Instant Bank Verification',
              value: 2
            }
          ]
        }
      }
    }
  },
  mounted () {
    const type = this.$store.getters.getLoan.incompleteScriptType
    if (type === DataType.NOIA_SITUATION.NOT_QUALIFIED.value) {
      this.layouts.data.title = 'Change and Re-authorize Bank Account'
      this.layouts.data.icon = 'mdi-bank-transfer'
      this.layouts.data.primaryText = 'To ensure information security and faster process, we recommend you use a new bank account for the Instant Bank Verification authorization process. Your convenience and information security are our utmost concern.'
    } else {
      this.layouts.data.title = 'Bank Account Verification Options'
      this.layouts.data.icon = 'mdi-folder-upload-outline'
      this.layouts.data.primaryText = 'To ensure information security and expedite the process, please continue the process with following options:'
      this.layouts.data.radioGroupDisplay = true
    }
  },
  methods: {
    onClickContinue () {
      const type = this.$store.getters.getLoan.incompleteScriptType
      const UPLOAD_BANK_STATEMENT = 1
      // const CHANGE_OR_REAUTHORIZE = 2
      if (type === DataType.NOIA_SITUATION.NOT_QUALIFIED.value) {
        this.$router.push('/guidance')
      } else {
        this.layouts.data.radioGroupValue === UPLOAD_BANK_STATEMENT ? this.$router.push('/upload-bank-statement') : this.$router.push('/guidance')
      }
    }
  }
}
</script>

<style scoped>

</style>
